<template>
<v-row class="main-bg-rebel" v-if="expo != null">
  <v-col cols="12" sm="11" md="11" lg="9" class="mx-auto">
    <v-card class="px-0" id="dialogExpo" color="transparent" elevation="0">

      <v-row>
        <v-col cols="12" 
              :sm="expo.gallery.length == 0 ? '12' : '12'" 
              :md="expo.gallery.length == 0 ? '12' : '12'"
              :class="expo.gallery.length == 0 ? 'mx-auto' : ''"
               class="pb-0 mt-5" >

          <v-toolbar color="transparent" class="mb-2" elevation="0">
            <v-btn to="/expos" fab small color="grey darken-3" class="mr-2" elevation="0"
                   title="retour à la page précédente">
              <v-icon color="white">mdi-arrow-left</v-icon>
            </v-btn>
            <v-spacer/>
            <v-btn icon outlined large class="mx-1" @click="goToExpoBack()">
              <v-icon>mdi-chevron-left</v-icon>
            </v-btn>
            <v-btn icon outlined large class="mx-1" @click="goToExpoNext()">
              <v-icon>mdi-chevron-right</v-icon>
            </v-btn>
          </v-toolbar>

          <v-divider class="my-5"/>

          <v-row class="ml-0">
            <v-col cols="12" sm="4" class="pl-0 mb-5">
                <v-img :src="baseUrl() + expo.image" 
                        @click="showDialogImg = true" 
                        class="cursor-pointer" 
                        :class="expo.isCircle ? 'img-rounded-dialog' : ''"/>
            </v-col>
            <v-col cols="12" sm="8">
              <v-card-title class="title-dialog-product font-dancing">
                {{ expo.name }}
              </v-card-title>
              <v-card-text class="pa-5" v-html="nl2br(expo.description)"/>
            </v-col>
          </v-row>

        </v-col>
      </v-row>
      
      <v-col cols="12" class="mt-5" v-if="expo.gallery.length > 0">
        <GalleryGrid :gallery="expo.gallery"/>
      </v-col>

    </v-card>
  </v-col>

  <v-dialog fullscreen v-model="showDialogImg">
    <v-card color="#1E1E1EF7">
      <v-row class="ma-0 py-0">
        <v-col cols="12" sm="10" md="8" class="mx-auto" style="margin-top:50px;">
          <img :src="baseUrl() + expo.image" contain  
                  max-width="100%" max-height="700"
                  style="border-radius:8px; max-height:800px; max-width:100%;" 
                  class="mx-auto my-5 d-flex"
                  :class="expo.isCircle ? 'img-rounded-dialog' : ''"/>
        </v-col>
      </v-row>

      <v-toolbar class="px-3 toolbar-popup" style="position:fixed; top:0%;" color="transparent" height="70" elevation="0">
        <v-spacer/>
        <v-btn icon dark outlined @click="showDialogImg = false" >
          <v-icon small>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
    </v-card>
  </v-dialog> 

</v-row>
</template>


<style></style>

<script>

//import axios from 'axios'
import router from '../router/router'
import core from '../plugins/core.js'

import GalleryGrid from '@/components/main-layout/GalleryGrid.vue'

export default {
  name: 'expo',
  components: { GalleryGrid },
  data: () => ({
    expo: null,
    allExpos: [],
    showDialogImg: false,
    showDialogRepro: false,
    dialogImgUrl: ''
  }),
  async mounted(){
    
    this.init()
    this.$store.dispatch('app/incStat', '/expo')

  },
  methods: {
    async init(){
      await this.$store.dispatch('app/fetchEntities', { entityType: "expo", query: { _id: this.$route.params.expoId } })
      this.expo = this.$store.state.app.data.expo[0]
      
      if(this.allExpos.length == 0)
      this.allExpos = await this.$store.dispatch('app/fetchEntities', 
                                            { entityType: "expo", 
                                              query: { public: true } 
                                            })

    },
    goToExpoBack(){
      let idm = ''
      let idx = ''
      this.allExpos.forEach((prod)=>{
        if(prod._id == this.expo._id) idm = idx
        idx = prod._id
      })
      if(idm == '') idm = this.allExpos[this.allExpos.length-1]._id
      this.$router.push("/expo/"+idm)
      this.$store.dispatch('app/incStat', '/expo')
    },
    goToExpoNext(){
      let idm = ''
      let found = false
      this.allExpos.forEach((prod)=>{
        if(found) idm = prod._id
        found = (prod._id == this.expo._id)
      })
      if(idm == '') idm = this.allExpos[0]._id
      this.$router.push("/expo/"+idm)
      this.$store.dispatch('app/incStat', '/expo')
    },
    goToExpo(id){
      this.$router.push("/expo/" + id)
    },
    goBack(){ router.back() },
    nl2br: function(str, is_xhtml){
      return core.nl2br(str, is_xhtml)
    },
    baseUrl(){ return core.baseUrl() }

  },
  computed: {
  },
  watch: {
      '$route.params.expoId': { immediate: true, 
          async handler () {
              this.init()
          }
      },
  }, 
}
</script>
